import React from 'react';
import { BiLogger } from './types';

export interface BiLoggerProviderProps {
  biLogger: BiLogger;
}

export const BiLoggerContext = React.createContext<BiLogger>(null);

export const BiLoggerProvider: React.FC<React.PropsWithChildren<BiLoggerProviderProps>> = ({ biLogger, children }) => (
  <BiLoggerContext.Provider value={biLogger}>{children}</BiLoggerContext.Provider>
);
