import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import React from 'react';
import { PaymentMethodsBannerInternalProps } from '../../types/PaymentMethodsBannerProps';
import { InteractionName } from '../../utils/fedopsLogger/InteractionName';
import { initI18n } from '../../utils/i18n';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';
import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';
import { PaymentMethodsBannerFC } from './component';

const DEBOUNCE_TIME = 200;

export interface PaymentMethodsBannerState {
  isLoading: boolean;
  isLoadingMethods: boolean;
  isError: boolean;
  bnplMethods: ClientBnplMethodInfo[];
}

export type PaymentMethodsBannerProps = PaymentMethodsBannerInternalProps & { captureException(error: Error): void };

// TODO: move all logic to /component
export class PaymentMethodsBanner extends React.Component<PaymentMethodsBannerProps, PaymentMethodsBannerState> {
  static defaultProps: Partial<PaymentMethodsBannerInternalProps> = {
    theme: 'light',
    deviceType: 'desktop',
  };

  state: PaymentMethodsBannerState = {
    isLoading: true,
    isLoadingMethods: true,
    isError: false,
    bnplMethods: [],
  };

  private _isMounted = true;
  private fullLoadDependencies: Promise<void>[] = [];

  constructor(props: PaymentMethodsBannerProps) {
    super(props);

    props.fedopsLogger.interactionEnded(InteractionName.BannerComponentLoad);
    props.fedopsLogger.interactionStarted(InteractionName.BannerRender);
    experimentsService.init(this.props.bannerSettings.experiments);

    this.addFullLoadDependency(initI18n(props.locale || 'en'));
  }

  componentDidMount() {
    this.addFullLoadDependency(this.fetchBnplMethodsAndSetToState());
    this.initLoadListener();
  }

  componentDidUpdate(prevProps: PaymentMethodsBannerInternalProps, prevState: PaymentMethodsBannerState) {
    if (prevProps.amount !== this.props.amount) {
      this.setState({
        // This is commented out because for product reasons we don't want to hide the banner
        //  when the amount changes (thus showing the old view and data until new BNPL methods are fetched).
        // isLoading: true, isError: false,
        isLoadingMethods: true,
      });

      this.fetchBnplMethodsAndSetToStateDebounced()
        .catch(this.handleLoadError)
        .finally(() => {
          this.setState({ isLoadingMethods: false });
        });
    }

    if (
      !this.state.isLoading &&
      (prevState.isLoading || // initial load
        (!this.state.isLoadingMethods && prevState.isLoadingMethods)) && // methods re-fetch upon "amount" change
      !this.state.isError
    ) {
      const { bnplMethods } = this.state;

      this.props.onFullLoad?.({
        paymentMethods: bnplMethods.map(({ paymentMethod }) => paymentMethod as unknown as InstallmentPaymentMethod),
      });

      if (!bnplMethods.length) {
        this.props.onEmpty?.();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private fetchBnplMethodsAndSetToStateDebounced = window?._?.debounce
    ? window._.debounce(this.fetchBnplMethodsAndSetToState, DEBOUNCE_TIME, {
        leading: true,
      })
    : this.fetchBnplMethodsAndSetToState;

  private async fetchBnplMethodsAndSetToState(): Promise<void> {
    this.setState({ isLoadingMethods: true });

    this.props.fedopsLogger.interactionStarted(InteractionName.FetchBnplMethods);
    const bnplMethods = await this.props.fetchBuyNowPayLaterMethods();
    this.props.fedopsLogger.interactionEnded(InteractionName.FetchBnplMethods);

    this.setStateSafe({ bnplMethods, isLoadingMethods: false, isError: false });
  }

  addFullLoadDependency = (dependency: Promise<any>) => {
    this.fullLoadDependencies.push(dependency);
  };

  handleLoadError = (error: Error) => {
    this.setStateSafe({ isError: true });
    this.props.captureException?.(error);
  };

  initLoadListener = async () => {
    try {
      await Promise.all(this.fullLoadDependencies);
      this.reportBannerFullLoad();
    } catch (error) {
      this.handleLoadError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private setStateSafe(state: Partial<PaymentMethodsBannerState>) {
    if (this._isMounted) {
      this.setState(state as PaymentMethodsBannerState);
    }
  }

  reportBannerFullLoad = () => {
    this.props.fedopsLogger.interactionEnded(InteractionName.BannerRender);
  };

  render() {
    const { isLoading, isError, bnplMethods } = this.state;

    if (isLoading || isError) {
      return null;
    }

    return <PaymentMethodsBannerFC {...this.props} bnplMethods={bnplMethods} />;
  }
}
