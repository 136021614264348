import { InstallmentPaymentMethod } from '../types/InstallmentPaymentMethod';

export const InstallmentPMLabels: Record<InstallmentPaymentMethod, string> = {
  [InstallmentPaymentMethod.Afterpay]: 'Afterpay',
  [InstallmentPaymentMethod.Affirm]: 'Affirm',
  [InstallmentPaymentMethod.Clearpay]: 'Clearpay',
  [InstallmentPaymentMethod.Laybuy]: 'Laybuy',
  [InstallmentPaymentMethod.Sezzle]: 'Sezzle',
  [InstallmentPaymentMethod.Atome]: 'Atome',
  [InstallmentPaymentMethod.PayPal]: 'PayPal',
};
