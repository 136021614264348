import React from 'react';
import { sentryConfig } from '../utils/Sentry/config';
import { PaymentMethodsComponentLoaderProps } from '../types/PaymentMethodsBannerProps';
import { isBannerEvent } from '../utils/Sentry';
import { SentryContext } from '../context/Sentry';

export const withSentryWrapper = (ComponentToWrap) => {
  return class SentryErrorBoundary extends React.Component<PaymentMethodsComponentLoaderProps> {
    state = {
      error: null,
    };

    getLazySentry() {
      try {
        return new this.props.LazySentry({
          ...sentryConfig,
          beforeSend: (event) => {
            if (!isBannerEvent(event)) {
              return null;
            }
            return event;
          },
        });
      } catch {
        console.error('Failed to init LazySentry');
      }
    }

    public captureException = (error) => {
      if (this.state.error === null) {
        this.setState({ error });
      }

      const sentryInstance = this.getLazySentry();
      if (!sentryInstance) {
        return;
      }

      const tags = {
        isSSR: this.props.isSSR,
        deviceType: this.props.deviceType,
        msid: this.props.meta.msid,
        appId: this.props.meta.appDefId,
        visitorId: this.props.meta.visitorId,
        currency: this.props.currency,
        amount: this.props.amount,
      };
      sentryInstance.configureScope((scope) => {
        Object.entries(tags).forEach(([tag, value]) => {
          scope.setTag(tag, value);
        });
      });

      sentryInstance.captureException(error);
    };

    componentDidCatch(error) {
      this.captureException(error);
    }

    render() {
      if (this.state.error) {
        return null;
      }

      return (
        <SentryContext.Provider value={{ captureException: this.captureException }}>
          <ComponentToWrap {...this.props} captureException={this.captureException} />
        </SentryContext.Provider>
      );
    }
  };
};
