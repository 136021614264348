import { getModuleRegistry } from '@wix/cashier-common/dist/src/rmc-fork/ModuleRegistry';
import { BANNER_COMPONENT_ID } from '../../lazy/config';
import { PaymentMethodsBannerRoot } from './root';

export const registerBannerInModuleRegistry = () => {
  getModuleRegistry().registerComponent(BANNER_COMPONENT_ID, () => PaymentMethodsBannerRoot);
};

registerBannerInModuleRegistry();

export { PaymentMethodsBannerRoot as PaymentMethodsBanner };
