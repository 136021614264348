import { BANNER_BUNDLE_FILE_NAME } from '../../lazy/config';

export const isBannerEvent = (event): boolean => {
  try {
    const stacktrace = event.stacktrace || event.exception?.values[0]?.stacktrace;
    const frames = stacktrace?.frames || [];

    return frames[frames.length - 1].filename.includes(BANNER_BUNDLE_FILE_NAME);
  } catch (error) {
    return false;
  }
};
