import React, { useCallback, useMemo, useRef } from 'react';
import { PaymentMethodsBannerInternalProps } from '../../types/PaymentMethodsBannerProps';
import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';
import { BiLoggerProvider, createBiLogger } from '../../utils/biLogger';
import { TopProps, TopPropsContext } from '../../context/TopProps';
import { DH } from '../../utils/DataHook';
import { PaymentMethodsList } from '../PaymentMethodsList';

export const PaymentMethodsBannerFC = (
  props: PaymentMethodsBannerInternalProps & { bnplMethods: ClientBnplMethodInfo[] },
) => {
  const biLoggerRef = useRef(createBiLogger(props.biLoggerFactory));
  const getStaticsBaseUrl = useCallback(() => {
    let baseUrl: string = props.bannerSettings.bannerStaticsBaseUrl;

    if (baseUrl) {
      if (baseUrl.startsWith('//')) {
        baseUrl = document.location.protocol + baseUrl;
      }

      return baseUrl;
    }

    return '/';
  }, [props.bannerSettings]);
  const topProps: TopProps = useMemo(
    () => ({
      deviceType: props.deviceType,
      theme: props.theme,
      textAlign: props.textAlign,
      delayedCapture: props.delayedCapture,
      openModal: props.openModal,
      currency: props.currency,
      wiredComponents: props.wiredComponents,
      staticsBaseUrl: getStaticsBaseUrl(),
    }),
    [
      props.deviceType,
      props.theme,
      props.textAlign,
      props.currency,
      props.wiredComponents,
      props.delayedCapture,
      getStaticsBaseUrl,
      props.openModal,
    ],
  );

  return (
    <BiLoggerProvider biLogger={biLoggerRef.current}>
      <TopPropsContext.Provider value={topProps}>
        <div data-hook={DH.Banner}>
          <PaymentMethodsList bnplMethods={props.bnplMethods} />
        </div>
      </TopPropsContext.Provider>
    </BiLoggerProvider>
  );
};
