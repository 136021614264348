/**
 * Flatten array
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#use_a_stack
 */
export const flatten = <T extends unknown>(input: T[]): T[] => {
  const stack = [...input];
  const res = [];

  while (stack.length) {
    const next = stack.pop();

    if (Array.isArray(next)) {
      stack.push(...next);
    } else {
      res.push(next);
    }
  }

  return res.reverse();
};
