import React from 'react';
import { useTheme } from '../../../context/TopProps';
import s from './logoStyles.scss';

export const AtomeLogo = () => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="20" viewBox="0 0 77 20">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  className={s[theme]}
                  d="M36.117 4.862c-1.074 0-1.929.411-2.474 1.19l-.076.108-.074-.11c-.528-.777-1.35-1.188-2.375-1.188-.98 0-1.78.377-2.31 1.092l-.071.087-.44-.98-2.467-.003.662 1.474v6.445h2.4V8.405c0-.982.516-1.569 1.382-1.569.856 0 1.366.587 1.366 1.57v4.571h2.4V8.405c0-.982.516-1.569 1.382-1.569.856 0 1.366.587 1.366 1.57v4.571h2.4V8.073c0-1.95-1.206-3.21-3.07-3.21zm10.126 3.336l-.023-.11c-.186-.898-.857-1.434-1.795-1.434-.948 0-1.625.536-1.811 1.434l-.023.11h3.652zm2.08-.28v1.989h-5.677l.036.119c.183.594.616 1.008 1.252 1.212.527.154 1.234.146 1.763-.016.48-.147.763-.306.763-.306l1.25 1.407c-.794.535-1.863.853-2.871.853-2.668 0-4.46-1.67-4.46-4.157 0-2.409 1.701-4.157 4.046-4.157 2.027 0 3.447 1.113 3.898 3.056zm-25.58 1.101c0-1.14-.811-1.967-1.929-1.967-1.118 0-1.929.827-1.929 1.967 0 1.14.811 1.968 1.93 1.968 1.117 0 1.928-.828 1.928-1.968zm2.4 0c0 1.176-.464 2.247-1.305 3.015-.807.737-1.88 1.142-3.024 1.142-1.143 0-2.217-.405-3.023-1.142-.842-.768-1.305-1.839-1.305-3.015 0-1.176.463-2.246 1.305-3.015.806-.736 1.88-1.142 3.023-1.142 1.144 0 2.217.406 3.024 1.142.84.769 1.304 1.84 1.304 3.015zM6.256 8.936c-.05-1.093-.862-1.884-1.929-1.884-1.118 0-1.929.827-1.929 1.967 0 1.14.811 1.968 1.93 1.968 1.067 0 1.878-.791 1.928-1.88v-.17zm2.4 2.32l.468 1.721H6.963l-.306-1.102-.111.119c-.712.762-1.676 1.182-2.714 1.182-1.01 0-1.959-.404-2.672-1.138C.412 11.27 0 10.197 0 9.02 0 7.842.412 6.77 1.16 6c.713-.734 1.662-1.138 2.672-1.138.99 0 1.913.38 2.6 1.069l.156.156V5.061h2.068v6.195zm4.981-6.195h2.55l-.879 1.974h-1.67v2.88c0 .734.302 1.076.951 1.076l.687.01v1.976h-1.15c-2.024 0-2.888-.781-2.888-2.613V7.035h-1.142V5.061h1.142V3.103h2.4V5.06z"
                  transform="translate(-190 -172) translate(0 160) translate(190 12) translate(4 1)"
                />
                <path
                  fill="#F0FF5F"
                  d="M65.758 14.905H55.1c-1.584 0-2.87-1.269-2.87-2.835V2.838c0-1.565 1.286-2.834 2.87-2.834h10.658c1.585 0 2.87 1.269 2.87 2.834v9.232c0 1.566-1.285 2.835-2.87 2.835"
                  transform="translate(-190 -172) translate(0 160) translate(190 12) translate(4 1)"
                />
                <path
                  fill="#000"
                  d="M65.182 9.277c.472-.534.933-1.157 1.381-1.87h-2.518c-1.126 1.456-2.254 2.18-3.364 2.154-.65-.015-1.277-.293-1.841-.688l1.84-3.06.39.637c.56.796 1.208.384 1.494.191.393-.264.711-.538.711-.538l-2.594-4.306-6.733 11.186h2.418l1.403-2.33c.819.55 1.78.956 2.855.983l.118.001c.99 0 1.95-.317 2.873-.948l1.38 2.294h2.418l-2.23-3.706z"
                  transform="translate(-190 -172) translate(0 160) translate(190 12) translate(4 1)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
