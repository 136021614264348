import React, { useCallback } from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-payments-data/v2';
import { useTranslation } from 'react-i18next';
import { isInModal } from '../../../utils/isInModal';
import { getModalSize } from '../../../utils/modalSize';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { InstallmentPaymentMethod } from '../../../types/InstallmentPaymentMethod';
import { InstallmentPMLabels } from '../../../utils/labels';
import { useTopProps } from '../../../context/TopProps';
import { Container, CtaModalTrigger, NoWrapInline } from './elements';

export interface GenericCollapsedMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const GenericCollapsedMethod = ({ pmInfo }: GenericCollapsedMethodProps) => {
  const biLogger = useBiLogger();
  const { t } = useTranslation();
  const { deviceType, openModal } = useTopProps();
  const { paymentMethod, bnplModalPage } = pmInfo;
  const sizeFromServer = bnplModalPage?.resolution;
  const modalUrl = bnplModalPage?.url;
  const modalSize = getModalSize(deviceType, paymentMethod, sizeFromServer);
  const paymentMethodLabel =
    paymentMethod === InstallmentPaymentMethod.CreditCard
      ? t('collapsedView.pm.creditCard')
      : InstallmentPMLabels[paymentMethod];

  const onCtaTextClick = useCallback(() => {
    biLogger.report(installmentBannerClickedMoreInfo({ paymentProvider: paymentMethod as string }));
    // Should not be clickable if is in modal
    if (openModal && modalUrl && !isInModal()) {
      openModal(modalUrl, modalSize.width, modalSize.height, () => {});
    }
  }, [openModal, modalUrl, biLogger, modalSize, paymentMethod]);

  if (!paymentMethodLabel) {
    return null;
  }

  return (
    <Container>
      {onCtaTextClick ? (
        <CtaModalTrigger ctaText={paymentMethodLabel} onCtaTextClick={onCtaTextClick} />
      ) : (
        <NoWrapInline>{paymentMethodLabel}</NoWrapInline>
      )}
    </Container>
  );
};
