import * as i18nextImport from 'i18next';
// in "npx yoshi build" setup ^ import produces a module object with "default" that equals i18next object
// while in tests env ("npx yoshi test" / wallaby) it's straightaway i18next object
// related thread: https://github.com/i18next/i18next/issues/1177
const i18next = i18nextImport.default || (i18nextImport as any);
import { initReactI18next } from 'react-i18next';

export function initI18n(locale: string): Promise<void> {
  return i18next
    .use(initReactI18next)
    .use({
      type: 'backend',
      read: (
        language: string,
        namespace: string,
        callback: (err: any | null, translations?: Record<string, string>) => void,
      ) => {
        return import(/* webpackChunkName: "[request]" */ `../assets/locales/messages_${language}.json`)
          .then((translation: Record<string, string>) => callback(null, translation))
          .catch((error) => callback(error));
      },
    })
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
      },
    })
    .then(() => {
      if (i18next.languages.every((lang: string) => i18next.getDataByLanguage(lang) === undefined)) {
        throw new Error(`Failed to load localization for "${locale}" lang`);
      }
    });
}

export const t = (key: string, options?: any) => i18next.t(key, options);
