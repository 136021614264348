import React from 'react';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { GenericCollapsedMethod } from '../methods/GenericCollapsed';
import { DH } from '../../utils/DataHook';
import { useTextAlign } from '../../context/TopProps';
import { PaymentMethodsListProps } from './types';
import s from './styles.scss';

const dynamicListProps = { i18nisdynamiclist: 'true' };

export const PaymentMethodsListViewCollapsed = ({ bnplMethods }: PaymentMethodsListProps) => {
  const textAlign = useTextAlign();
  const facingMethods = bnplMethods.slice(0, -1);
  const trailingMethod = bnplMethods.slice(-1)[0];

  return (
    <div
      className={cn(s.line, textAlign && s[`text-align-${textAlign}`])}
      data-hook={DH.PaymentMethodView.CollapsedView}
    >
      <Trans i18nKey={'generic.view.breakdownMessage'}>
        or buy now, pay later with
        <span {...dynamicListProps}>
          {facingMethods.map((pm, index) => (
            <React.Fragment key={pm.paymentMethod}>
              <GenericCollapsedMethod pmInfo={pm} />
              {index < facingMethods.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </span>
        <GenericCollapsedMethod pmInfo={trailingMethod} />
      </Trans>
    </div>
  );
};
