import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { DH } from '../../../utils/DataHook';
import { Container, NoWrapContainer } from '../GenericOld/elements';
import { prettyNumber } from '../../../utils/amount';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { useCurrency } from '../../../context/TopProps';

export interface CreditCardMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const CreditCardMethod = ({ pmInfo }: CreditCardMethodProps) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  if (pmInfo.paymentOptions.length === 0) {
    return null;
  }

  const title = t('creditCardInstallments.view.title', {
    currency: getSymbolFromCurrency(currency),
    maxInstallments: pmInfo.paymentOptions[0].installmentCount,
    minTransactionAmount: prettyNumber(pmInfo.paymentOptions[0].installmentAmount),
  });

  return (
    <Container dataHook={DH.PaymentMethodItem.CreditCard}>
      <NoWrapContainer>{title}</NoWrapContainer>
    </Container>
  );
};
