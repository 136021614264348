import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentProvider } from '@wix/cashier-common/dist/src/enums/payments/PaymentProvider';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-payments-data/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { DeviceType } from '../../../types/DeviceType';
import { DH } from '../../../utils/DataHook';
import { LogoWrap, NoWrapContainer } from '../GenericOld/elements';
import { flatten } from '../../../utils/flatten';
import { Text } from '../../Text';
import { TextButton } from '../../TextButton';
import { Image } from '../../Image';
import { CtaModalTrigger } from '../Generic/elements';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { useTopProps } from '../../../context/TopProps';
import { getModalSize } from '../../../utils/modalSize';
import s from './styles.scss';

export type AffirmMessageItem = TextItem | LogoItem | ModalTriggerItem;

export type TextItem = {
  type: 'text';
  content: string;
};
export type LogoItem = {
  type: 'logo';
};
export type ModalTriggerItem = {
  type: 'modal-trigger';
  content: string;
};

export interface AffirmElementsProps {
  logoSrc: string;
  onModalTriggerClick(): void;
  hideModalTrigger: boolean;
  deviceType: DeviceType;
}

export const getAffirmReactComponents = (msg: string, props: AffirmElementsProps): (() => React.ReactElement)[] => {
  try {
    return affirmItemsToReactElems(generateAffirmMessageItems(msg), props);
  } catch (e) {
    return [];
  }
};

/**
 * Builds React elements from Affirm Message Items
 * @param {AffirmMessageItem []} items
 * @param {AffirmElementsProps} props
 * @returns React Elements
 */
export function affirmItemsToReactElems(items: AffirmMessageItem[], props: AffirmElementsProps) {
  return items.map((item: AffirmMessageItem) => {
    switch (item.type) {
      case 'text':
        return () => {
          return <Text className={s.nowrap}>{item.content}</Text>;
        };
      case 'logo':
        return () => (
          <LogoWrap>
            <Image src={props.logoSrc} className={s.logo} alt="Affirm logo" />
          </LogoWrap>
        );
      case 'modal-trigger': {
        if (props.hideModalTrigger) {
          return () => {
            return null;
          };
        }

        let className = s.modalTrigger;

        if (props.deviceType === 'desktop') {
          className += ` ${s.extraSpace}`;
        }

        return () => {
          return (
            <TextButton
              data-hook={DH.PaymentMethodView.ModalTrigger}
              onClick={props.onModalTriggerClick}
              customElClassName={className}
            >
              {item.content}
            </TextButton>
          );
        };
      }
      default:
        return () => null;
    }
  });
}

const ELEMENTS_TO_IGNORE = ['SCRIPT', 'STYLE', 'LINK'];
/**
 * Splits HTML string (response from Affirm's API) to building items
 * that will be used for manipulating.
 * @param {String} str HTML
 * @returns {AffirmMessageItem[]}
 */
export function generateAffirmMessageItems(str: string): AffirmMessageItem[] {
  const root = document.createElement('div');
  root.innerHTML = str;
  const nodes = root.childNodes;

  const getItem = (node: Node | Element) => {
    switch (node.nodeType) {
      case Node.TEXT_NODE:
        return { type: 'text', content: node.nodeValue };
      case Node.ELEMENT_NODE: {
        const element = node as Element;
        if (ELEMENTS_TO_IGNORE.includes(element.tagName.toUpperCase())) {
          return null;
        }

        if (element.classList.contains('__affirm-logo')) {
          return { type: 'logo' };
        }

        if (element.classList.contains('affirm-modal-trigger')) {
          return { type: 'modal-trigger', content: element.innerHTML };
        }

        if (element.hasChildNodes()) {
          return Array.prototype.map.call(element.childNodes, getItem);
        }
        return null;
      }
      default:
        return null;
    }
  };
  const items = Array.prototype.map.call(nodes, getItem);
  return sanitizeItems(flatten(items));
}

/**
 * Combines sibling text items into one, skip empty text items.
 * @param {T extends AffirmMessageItem[]} arr
 * @returns {T extends AffirmMessageItem[]} new array
 */
export const sanitizeItems = <T extends AffirmMessageItem>(arr: T[]): T[] => {
  return arr.reduce((acc, i) => {
    if (!i) {
      return acc;
    }
    if (i.type !== 'text') {
      acc.push(i);
      return acc;
    }
    const item = i as TextItem;
    if (acc.length > 0 && acc[acc.length - 1].type === 'text') {
      acc[acc.length - 1].content += item.content;
      return acc;
    }
    acc.push(i);
    return acc;
  }, []);
};

/**
 * Static view of the Affirm banner for case when it's under WIX Payments and doesn't have additional data as "promoMessage" && "modal-trigger" from BE.
 */
export const StaticAffirmView = (props: { logoSrc: string }) => {
  const { t } = useTranslation();
  const biLogger = useBiLogger();
  const { deviceType, openModal } = useTopProps();
  const modalSize = getModalSize(deviceType, PaymentMethod.Affirm);
  const modalUrl = 'https://www.affirm.com/apps/prequal';

  const onCtaTextClick = useCallback(() => {
    biLogger?.report(installmentBannerClickedMoreInfo({ paymentProvider: PaymentProvider.WixPayUS as string }));

    if (openModal) {
      openModal(modalUrl, modalSize.width, modalSize.height, () => {});
    }
  }, [openModal, modalSize, biLogger]);

  return (
    <>
      <NoWrapContainer>
        <Text className={s.nowrap}>{t('affirm.view.staticTitle')}</Text>
        <LogoWrap>
          <Image src={props.logoSrc} className={s.logo} alt="Affirm logo" />
        </LogoWrap>
      </NoWrapContainer>
      <CtaModalTrigger ctaText={t('affirm.view.ctaText')} onCtaTextClick={onCtaTextClick} />
    </>
  );
};
