import React from 'react';
import cn from 'classnames';
import { DH } from '../../utils/DataHook';
import { useTextAlign } from '../../context/TopProps';
import { PaymentMethodItem } from './elements';
import { PaymentMethodsListProps } from './types';
import s from './styles.scss';

export const PaymentMethodsListView = ({ bnplMethods }: PaymentMethodsListProps) => {
  const textAlign = useTextAlign();

  return (
    <div
      data-hook={DH.PaymentMethodView.ExpandedView}
      className={cn(s.container, textAlign && s[`text-align-${textAlign}`])}
    >
      {bnplMethods.map((pmInfo) => (
        <PaymentMethodItem key={pmInfo.paymentMethod} pmInfo={pmInfo} />
      ))}
    </div>
  );
};
