import React from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-payments-data/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { PaymentProvider } from '@wix/cashier-common/dist/src/enums/payments/PaymentProvider';
import { isInModal } from '../../../utils/isInModal';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { useTopProps } from '../../../context/TopProps';
import { getDetailsPageUrl } from './detailsPage';
import { getAffirmReactComponents, StaticAffirmView } from './getAffirmReactComponents';
import { Container } from './elements';
import { AFFIRM_MODAL_SIZE } from './constants';

export interface AffirmMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const AffirmMethod = ({ pmInfo }: AffirmMethodProps) => {
  const biLogger = useBiLogger();
  const { theme = 'light', deviceType = 'desktop', staticsBaseUrl, openModal } = useTopProps();
  const logoSrc = theme === 'light' ? pmInfo?.logo.logoLight : pmInfo?.logo.logoDark;
  const hideModalTrigger = isInModal() || openModal === undefined;
  const detailsPageUrl = getDetailsPageUrl({
    staticsBaseUrl,
    contentUrl: encodeURIComponent(pmInfo.infoUrl),
    deviceType,
  });

  if (pmInfo.paymentProviderName === PaymentProvider.WixPayUS && !pmInfo.promoMessage) {
    return (
      <Container>
        <StaticAffirmView logoSrc={logoSrc} />
      </Container>
    );
  }

  const AffirmElements = getAffirmReactComponents(pmInfo.promoMessage, {
    logoSrc,
    deviceType,
    onModalTriggerClick() {
      biLogger.report(installmentBannerClickedMoreInfo({ paymentProvider: PaymentMethod.Affirm }));
      openModal(detailsPageUrl, AFFIRM_MODAL_SIZE[deviceType].width, AFFIRM_MODAL_SIZE[deviceType].height, () => {});
    },
    hideModalTrigger,
  });

  return (
    <Container>
      {AffirmElements.map((AffirmElement, i) => (
        <AffirmElement key={i} />
      ))}
    </Container>
  );
};
