import React from 'react';
import { DH } from '../../../utils/DataHook';
import s from './styles.scss';

export const Container: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div data-hook={DH.PaymentMethodItem.Affirm} className={s.container}>
      {children}
    </div>
  );
};
