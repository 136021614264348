export const isInModal = () => getUrlParams(window.location)?.isInModal === 'true';

const getUrlParams = (location: Location) => {
  const hashes = location.search.slice(location.search.indexOf('?') + 1).split('&');
  return hashes.reduce<Record<string, string>>(function (params, hash) {
    const keyValue = hash.split('=');
    params[keyValue[0]] = decodeURIComponent(keyValue[1]);
    return params;
  }, {});
};
