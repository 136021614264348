import React from 'react';
import { useWiredComponents } from '../../context/TopProps';
import { WiredTextButtonProps } from './types';

type Props = WiredTextButtonProps & {
  // custom props will be applied only if wired component is not available
  customElClassName?: string;
};

export const TextButton: React.FC<Props> = ({ children, customElClassName, ...props }) => {
  const WiredComponents = useWiredComponents();

  if (WiredComponents?.TextButton) {
    return <WiredComponents.TextButton {...props}>{children}</WiredComponents.TextButton>;
  }

  return (
    <button className={customElClassName} {...props}>
      {children}
    </button>
  );
};
