export const splitTextIntoTwoParts = (
  text: string,
  params: {
    wordsFromEnd?: number;
    separator?: string;
  } = { wordsFromEnd: 1, separator: ' ' },
): {
  first: string;
  last: string;
} => {
  const { wordsFromEnd, separator } = params;

  if (!text?.length || text.length < wordsFromEnd) {
    return {
      first: text,
      last: '',
    };
  }

  const partsArr: string[] = text.split(separator);
  const lastPart = partsArr.splice(partsArr.length - wordsFromEnd, partsArr.length);

  if (partsArr.length) {
    return {
      first: partsArr.join(separator),
      last: lastPart.join(separator),
    };
  }

  return {
    first: text,
    last: '',
  };
};
