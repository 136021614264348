import React from 'react';
import { DH } from '../../../utils/DataHook';
import { Text } from '../../Text';
import { TextButton } from '../../TextButton';
import { Image } from '../../Image';
import { InstallmentPaymentMethodType } from '../../../types/InstallmentPaymentMethod';
import s from './styles.scss';

export const Container: React.FC<React.PropsWithChildren<{ paymentMethod: InstallmentPaymentMethodType }>> = ({
  paymentMethod,
  children,
}) => (
  <div data-hook={DH.PaymentMethodItem.Generic} data-pm={paymentMethod} className={s.container}>
    {children}
  </div>
);

export const LogoInline = ({ src }: { src: string }) => (
  <span data-hook={DH.PaymentMethodView.Logo} className={s.logo}>
    <Image src={src} alt="Payment method logo" />
  </span>
);

export const NoWrapInline: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <span className={s.noWrap}>{children}</span>
);

export const MessageElementInline: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Text className={s.messageElement}>{children}</Text>
);

export const CtaModalTrigger = ({ ctaText, onCtaTextClick }: { ctaText: string; onCtaTextClick(): void }) => (
  <TextButton data-hook={DH.PaymentMethodView.ModalTrigger} customElClassName={s.ctaLink} onClick={onCtaTextClick}>
    {ctaText}
  </TextButton>
);

export const ProviderAnalytics = ({ url }: { url?: string }) =>
  url ? <img src={url} className={s.hidden} alt="" /> : null;
