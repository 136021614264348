export const prettyNumber = (number: number): string => {
  if (!number) {
    return;
  }
  return number.toFixed(2);
};

export const parseFloatAmount = (amount: string | number): number => {
  const amountAsNumber = +parseFloat(amount as any).toFixed(2);

  if (!amountAsNumber) {
    return;
  }

  return amountAsNumber;
};
