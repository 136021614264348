import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';

export const sortPaymentMethodsByRank = (paymentMethods: ClientBnplMethodInfo[]): ClientBnplMethodInfo[] =>
  paymentMethods.sort((aMethod, bMethod) => aMethod.rank - bMethod.rank);

export const getBiOfferId = (methods: ClientBnplMethodInfo[]): string =>
  methods
    .map((pm) => pm.paymentMethod)
    .reverse()
    .join(',');

export const getPaymentProvider = (methods: ClientBnplMethodInfo[]) =>
  methods
    .filter((pm) => pm.paymentProviderName)
    .map((pm) => pm.paymentProviderName)
    .reverse()
    .join(',');
