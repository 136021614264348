import React from 'react';

export const DetailsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14">
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="M148 23c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm0 .778c-3.436 0-6.222 2.786-6.222 6.222 0 3.436 2.786 6.222 6.222 6.222 3.436 0 6.222-2.786 6.222-6.222 0-3.436-2.786-6.222-6.222-6.222zm.686 4.879v3.716l.445.022v.716h-1.909v-.686l.173-.017c.158-.014.285-.13.318-.28l.008-.077c.002-.007.003-.069.003-.185l.001-.165v-.99l-.004-1.099c-.001-.138-.062-.207-.182-.207h-.317v-.748h1.464zm-.668-2.546c.439 0 .795.356.795.796 0 .439-.356.795-.795.795-.44 0-.796-.356-.796-.795 0-.44.356-.796.796-.796z"
        transform="translate(-141 -35) translate(0 12)"
      />
    </g>
  </svg>
);
