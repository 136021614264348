import { createContext, useContext } from 'react';
import { WiredComponents } from '../types/WiredComponents';
import { ThemeString } from '../types/Theme';
import { DeviceType } from '../types/DeviceType';
import { Currency } from '../types/public-types';
import { OpenModalFn } from '../types/OpenModalFn';
import { TextAlign } from '../types/TextAlign';

export type TopProps = {
  deviceType?: DeviceType;
  theme?: ThemeString;
  textAlign?: TextAlign;
  currency?: Currency;
  wiredComponents?: WiredComponents;
  staticsBaseUrl?: string;
  delayedCapture?: boolean;

  openModal?: OpenModalFn;
};

export const TopPropsContext = createContext<TopProps>(null);

export const useTopProps = (): TopProps => {
  return useContext(TopPropsContext);
};

export const useWiredComponents = (): WiredComponents | null => {
  const { wiredComponents } = useTopProps();

  return wiredComponents;
};

export const useTheme = (): ThemeString => {
  const { theme } = useTopProps();

  return theme;
};

export const useTextAlign = (): TextAlign => {
  const { textAlign } = useTopProps();

  return textAlign;
};

export const useCurrency = (): Currency => {
  const { currency } = useTopProps();

  return currency;
};
