import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { prettyNumber } from '../../../utils/amount';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { GenericOldMethod } from '../GenericOld';
import { DH } from '../../../utils/DataHook';
import { useCurrency } from '../../../context/TopProps';
import { AtomeLogo } from './logo';

export interface AtomeMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const AtomeMethod = ({ pmInfo }: AtomeMethodProps) => {
  const { t } = useTranslation();
  const currency = useCurrency();
  const pOption = pmInfo.paymentOptions[0];

  if (!pOption) {
    return null;
  }

  const mainTitle = t('atome.view.mainTitle');
  const priceTitle = t('atome.view.priceTitle', {
    installment: prettyNumber(pOption.installmentAmount),
    currency: getSymbolFromCurrency(currency),
  });

  return (
    <GenericOldMethod
      dataHook={DH.PaymentMethodItem.Atome}
      paymentMethod={PaymentMethod.Atome}
      logo={<AtomeLogo />}
      mainTitle={mainTitle}
      priceTitle={priceTitle}
    />
  );
};
