import React from 'react';
import { DH } from '../../../utils/DataHook';
import { Text } from '../../Text';
import s from './styles.scss';

export const NoWrapContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <Text className={s.noWrapContainer}>{children}</Text>;
};

export const Container: React.FC<React.PropsWithChildren<{ dataHook: string }>> = ({ dataHook, children }) => {
  return (
    <div data-hook={dataHook} className={s.container}>
      {children}
    </div>
  );
};

export const LogoWrap: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <span data-hook={DH.PaymentMethodView.Logo} className={s.logo}>
      {children}
    </span>
  );
};
