import { InstallmentPaymentMethod, InstallmentPaymentMethodType } from '../types/InstallmentPaymentMethod';
import { DeviceType } from '../types/DeviceType';

export type ModalSize = {
  width: number;
  height: number;
};

/*
 * This modal sizes are stored temporary, until make sure they are coming from server.
 * Was put here to reduce duplication, as it is used in different components.
 * */
const commonModalSize: ModalSize = {
  width: 770,
  height: 610,
};

export const DesktopModalSize: Record<InstallmentPaymentMethod, ModalSize> = {
  [InstallmentPaymentMethod.Laybuy]: {
    width: 390,
    height: 525,
  },
  [InstallmentPaymentMethod.Affirm]: {
    width: 610,
    height: 610,
  },
  [InstallmentPaymentMethod.Afterpay]: commonModalSize,
  [InstallmentPaymentMethod.Clearpay]: commonModalSize,
  [InstallmentPaymentMethod.PayPal]: {
    width: 640,
    height: 620,
  },
  [InstallmentPaymentMethod.Sezzle]: {
    width: 620,
    height: 580,
  },
};

// workaround to open modal to 100%, openModal doesn't support string values
// https://wix.slack.com/archives/CAKBA7TDH/p1604926273350300?thread_ts=1604922926.343800&cid=CAKBA7TDH
export const MobileModalSize: ModalSize = {
  width: 2000,
  height: 2000,
};

export const getModalSize = (
  deviceType: DeviceType,
  paymentMethod: InstallmentPaymentMethodType,
  sizeFromServer?: ModalSize,
): ModalSize =>
  // Gets modal size depends on payment method, gracefully falling back to the default size (which is Afterpay)
  deviceType === 'mobile'
    ? MobileModalSize
    : sizeFromServer || DesktopModalSize?.[paymentMethod] || DesktopModalSize[InstallmentPaymentMethod.Afterpay];
